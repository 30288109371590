const INITIAL_STATE = {
  lista: {
    vendedores: []
  },
  vendedorSelecionado: null,
  detalhesVendedor: null,
  listaMenu: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'VENDEDORES_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'VENDEDOR_EDIT':
      return { ...state, vendedorSelecionado: action.payload.data.vendedor }
    case 'VENDEDOR_CLEAR':
      return { ...state, vendedorSelecionado: action.payload }
    case 'VENDEDOR_UPDATE':
      return { ...state }
    case 'VENDEDOR_CREATE':
      return { ...state }
    case 'VENDEDOR_SHOW':
      return { ...state, detalhesVendedor: action.payload.data.vendedor }
    case 'LISTA_MENU':
      return { ...state, listaMenu: action.payload.data.menu }
    default:
      return state
  }
}