import { useState, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
// import openSocket from "socket.io-client"

// import { toast } from "react-toastify"
// import toastError from "../../errors/toastError"
// import Toastify from '../../@core/components/toastify'

import api from "../../services/api"
import axios from "axios"

const useAuth = () => {
  const history = useHistory()
  const [isAuth, setIsAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})

  api.interceptors.request.use(
    async config => {
      const token = localStorage.getItem("token")
      if (token) {
        config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`
        setIsAuth(true)
      }
      return config
    },
    error => {
      Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      const originalRequest = error.config
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true
        // const { data } = await api.post("/auth/refresh_token")
        // if (data) {
        // 	// localStorage.setItem("token", JSON.stringify(data.token))
        // 	// api.defaults.headers.Authorization = `Bearer ${data.token}`
        // }
        return api(originalRequest)
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem("token")
        api.defaults.headers.Authorization = undefined
        setIsAuth(false)
      }
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    const token = localStorage.getItem('token');
    (async () => {
      if (token) {
        try {
          const userData = JSON.parse(localStorage.getItem('userData'))
          if (userData) {
            const user = {
              id: userData.adm_id,
              profile: userData.adm_idadmintipo === 1 ? 'admin' : '',
              name: userData.pessoa.pes_nome,
              email: userData.adm_email,
              permissions: userData.adm_idpermissao === 1 ? 'admin' : 'user',
              idempresa: userData.empresa.emp_id,
              queues: []
            }
            setIsAuth(true)
            setUser(user)
          } else {
            setIsAuth(false)
            setUser({})
          }
          // const { data } = await api.post("/auth/refresh_token")
          api.defaults.headers.Authorization = `Bearer ${token}`

        } catch (err) {
        }
      }
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    // const socket = openSocket(process.env.REACT_APP_BACKEND_URL)
    // socket.on("user", data => {
    // 	if (data.action === "update" && data.user.id === user.id) {
    // 		setUser(data.user)
    // 	}
    // })

    // return () => {
    // 	socket.disconnect()
    // }
  }, [user])

  const handleLoginServer = async userData => {
    setLoading(true)

    try {
      // const { data } = await api.post("/auth/login", userData)

      localStorage.setItem("token", JSON.stringify(userData.token))
      api.defaults.headers.Authorization = `Bearer ${userData.token}`
      // console.log('userData laravel: ', userData)
      // console.log('data node: ', data.user)
      // setUser(data.user)
      setUser(userData.user)
      setIsAuth(true)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleLogoutServer = async () => {
    setLoading(true)

    try {
      await api.delete("/auth/logout")
      setIsAuth(false)
      setUser({})
      localStorage.removeItem("token")
      api.defaults.headers.Authorization = undefined
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const useAuthRefresh = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}vendedores/editar/null`)
      
      if (data.status) {
          console.log('logoutAndMessage: ');
      } else {
        // console.log('data: ', data)
        if (data.vendedor) {
          const user = {
            id: data.vendedor.adm_id,
            profile: data.vendedor.adm_idadmintipo === 1 ? 'admin' : '',
            name: data.vendedor.pessoa.pes_nome,
            email: data.vendedor.adm_email,
            permissions: data.vendedor.adm_idpermissao === 1 ? 'admin' : 'user',
            idempresa: data.vendedor.adm_idempresa,
            acessoMenu: data.vendedor?.menu?.map(item => item.men_link),
            queues: data.vendedor?.setores
          }
          setIsAuth(true)
          setUser(user)
        }
      }
    } catch (err) {
      console.log('### err: ', err);
    }
  }

  return { isAuth, user, loading, handleLoginServer, handleLogoutServer , useAuthRefresh }
}

export default useAuth
