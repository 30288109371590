const initialState = {
  whatsApp: null,
  admin: {
    queues: []
  },
  admins: {},
  mensagensRapidas: {},
  ticketsQuantidade: 0
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_WHATSAPP':
      return { ...state, whatsApp: action.payload.data.whatsApp }
    case 'GET_TICKET_QUANTIDADE':
      return { ...state, ticketsQuantidade: action.payload.data.ticketsQuantidade }
    case 'GET_ADMIN':
      return { ...state, admin: action.payload.data.admin }
    case 'GET_ADMINS_TRANSFERENCIA':
      return { ...state, admins: action.payload.data.admins }
    case 'GET_MENSAGENS_RAPIDAS':
      return { ...state, mensagensRapidas: action.payload.data.mensagens }
    default:
      return state
  }
}

export default chatReducer
