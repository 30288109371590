import React, { createContext } from "react";

import useAuth from "../../hooks/useAuth.js";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const { loading, user, isAuth, handleLoginServer, handleLogoutServer, useAuthRefresh } = useAuth();

	return (
		<AuthContext.Provider
			value={{ loading, user, isAuth, handleLoginServer, handleLogoutServer, useAuthRefresh }}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
