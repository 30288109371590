const INITIAL_STATE = {
  lista: {
    campanhas: []
  },
  campanhaSelecionada: null,
  detalhesCampanha: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CAMPANHAS_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'CAMPANHA_EDIT':
      return { ...state, campanhaSelecionada: action.payload.data.campanha }
    case 'CAMPANHA_CLEAR':
      return { ...state, campanhaSelecionada: action.payload }
    case 'CAMPANHA_UPDATE':
      return { ...state }
    case 'CAMPANHA_CREATE':
      return { ...state }
    case 'CAMPANHA_SHOW':
      return { ...state, detalhesCampanha: action.payload.data.campanha }
    default:
      return state
  }
}