// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import clientes from '../../views/clientes/clientesReducer'
import carteira from '../../views/carteira/carteirasReducer'
import vendedores from '../../views/vendedores/vendedoresReducer'
import setores from '../../views/setores/setoresReducer'
import etiquetas from '../../views/configuracoes/etiquetas/etiquetasReducer'
import mensagens_categorias from '../../views/mensagens/categoriasReducer'
import mensagens from '../../views/mensagens/mensagensReducer'
import meuperfil from '../../views/meu-perfil/adminReducer'
import empresas from '../../views/empresas/empresasReducer'
import chat_template from '../../views/chat-template/store/reducer'
import chat from '../../views/chat/chatReducer'
import chat_bot from '../../views/chat-bot/chatBotReducer'
import lista_transmissao from '../../views/lista-transmissao/listaTransmissaoReducer'
import campanhas from '../../views/lista-transmissao/campanhaReducer'
import plano from '../../views/planos/planosReducer'
import atributo from './atributo'

const appReducer = combineReducers({
    auth,
    navbar,
    clientes,
    layout,
    carteira,
    vendedores,
    setores,
    etiquetas,
    mensagens_categorias,
    mensagens,
    meuperfil,
    empresas,
    chat_template,
    chat_bot,
    lista_transmissao,
    campanhas,
    plano,
    chat,
    atributo,
})

const rootReducer = (state, action) => {

    if (action.type === 'DESTROY_SESSION') state = undefined
    return appReducer(state, action);
}

export default rootReducer
