const INITIAL_STATE = {
  lista: {
    listaTransmissao: []
  },
  listaTransmissaoSelecionada: null,
  detalhesListaTransmissao: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
 
    case 'LISTA_TRANSMISSAO_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'LISTA_TRANSMISSAO_EDIT':
      return { ...state, listaTransmissaoSelecionada: action.payload.data.listaTransmissao }
    case 'LISTA_TRANSMISSAO_CLEAR':
      return { ...state, listaTransmissaoSelecionada: action.payload }
    case 'LISTA_TRANSMISSAO_UPDATE':
      return { ...state }
    case 'LISTA_TRANSMISSAO_CREATE':
      return { ...state }
    case 'LISTA_TRANSMISSAO_SHOW':
      return { ...state, detalhesListaTransmissao: action.payload.data.transmissao }
    default:
      return state
  }
}