const INITIAL_STATE = {
  lista: {
    categorias: []
  },
  categoriaSelecionada: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MENSAGENS_CATEGORIAS_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'MENSAGEM_CATEGORIA_EDIT':
      return { ...state, categoriaSelecionada: action.payload.data.categoria }
    case 'MENSAGEM_CATEGORIA_CLEAR':
      return { ...state, categoriaSelecionada: action.payload }
    case 'MENSAGEM_CATEGORIA_UPDATE':
      return { ...state }
    case 'MENSAGEM_CATEGORIA_CREATE':
      return { ...state }
    default:
      return state
  }
}