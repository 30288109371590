const INITIAL_STATE = {
    atributos: {
        data: []
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'ATRIBUTO_FETCH': {
            return { ...state, atributos: action.payload }
        }
        default:
            return state
    }
}