const INITIAL_STATE = {
  lista: {
    setores: []
  },
  setorSelecionado: null,
  detalhesSetor: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SETORES_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'SETOR_EDIT':
      return { ...state, setorSelecionado: action.payload.data.setor }
    case 'SETOR_CLEAR':
      return { ...state, setorSelecionado: action.payload }
    case 'SETOR_UPDATE':
      return { ...state }
    case 'SETOR_CREATE':
      return { ...state }
    case 'SETOR_SHOW':
      return { ...state, detalhesSetor: action.payload.data.setor }
    default:
      return state
  }
}