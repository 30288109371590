const INITIAL_STATE = {
  setores: [],
  botoes: [{
    texto: '',
    tipo: 'MENSAGEM',
    texto2: '',
    tipoLista: 'NUMERO'
  }],
  acoes: [],
  randomizador: [],
  condicao: [],
  conexaoFluxo: [{
    fluxoSelecionado: null,
    tipo: 'FLUXO',
    modalVariavel: false
  }],
  textAreas: [{
    texto: '',
    tipo: 'MENSAGEM',
    variaveis: [],
    modalVariavel: false,
    node: null
  }],
  conteudoNode: [{
    texto: '',
    tipo: 'MENSAGEM',
    variaveis: []
  }],
  botaoDelete: null,
  duplicaNode: {},
  etiquetas: [],
  calendly: [{
    tipo: "CONFIGURACAO",
    token: '',
    link: ''
  }]
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_SETORES':
      const setores = action.payload.data.setores
      setores.push({ value: 0, label: 'ChatBot' })
      return { ...state, setores: setores }
    case 'GET_CONTEUDO':
      const textAreas = action.payload.data.textAreas
      return { ...state, textAreas: [...state.textAreas, textAreas] }
    case 'EDIT_CONTEUDO':
      return { ...state, textAreas: action.payload }
    case 'REMOVE_CONTEUDO': {
      const textAreas = [...state.textAreas]
      textAreas.splice(action.id, 1)
      return { ...state, textAreas: textAreas }
    }
    case 'SET_CONTEUDO': {
      return { ...state, textAreas: action.payload }
    }

    case 'GET_BOTOES':
      const botoes = action.payload.data.botoes
      return { ...state, botoes: [...state.botoes, botoes] }
    case 'EDIT_BOTAO':
      // return { ...state, botoes: action.payload }
      return { ...state, botoes: action.payload ? action.payload : INITIAL_STATE.botoes }
    case 'REMOVE_BOTAO': {
      const botoes = [...state.botoes]
      botoes.splice(action.id, 1)
      return { ...state, botoes: botoes }
    }
    case 'SET_BOTAO': {
      return { ...state, botoes: action.payload }
    }

    case 'SET_ACAO': {
      return { ...state, acoes: action.payload }
    }
    case 'GET_ACAO':
      const acoes = action.payload.data.acoes
      return { ...state, acoes: [...state.acoes, acoes] }
    case 'EDIT_ACAO':
      return { ...state, acoes: action.payload }
    case 'REMOVE_ACAO': {
      const acoes = [...state.acoes]
      acoes.splice(action.id, 1)
      return { ...state, acoes: acoes }
    }

    case 'SET_RANDOMIZADOR': {
      return { ...state, randomizador: action.payload }
    }
    case 'GET_RANDOMIZADOR':
      const randomizador = action.payload.data.randomizador
      return { ...state, randomizador: [...state.randomizador, randomizador] }
    case 'EDIT_RANDOMIZADOR':
      return { ...state, randomizador: action.payload }
    case 'REMOVE_RANDOMIZADOR': {
      const randomizador = [...state.randomizador]
      randomizador.splice(action.id, 1)
      return { ...state, randomizador: randomizador }
    }

    case 'SET_CONDICAO': {
      return { ...state, condicao: action.payload }
    }
    case 'GET_CONDICAO':
      const condicao = action.payload.data.condicao
      return { ...state, condicao: [...state.condicao, condicao] }
    case 'EDIT_CONDICAO':
      return { ...state, condicao: action.payload }
    case 'REMOVE_CONDICAO': {
      const condicao = [...state.condicao]
      condicao.splice(action.id, 1)
      return { ...state, condicao: condicao }
    }

    case 'SET_CONEXAO_FLUXO': {
      return { ...state, conexaoFluxo: action.payload }
    }
    case 'GET_CONEXAO_FLUXO':
      const conexaoFluxo = action.payload.data.conexaoFluxo
      return { ...state, conexaoFluxo: [...state.conexaoFluxo, conexaoFluxo] }
    case 'EDIT_CONEXAO_FLUXO':
      const conexao = [...state.conexaoFluxo]
      conexao[0].fluxoSelecionado = action.payload.fluxo
      console.log(conexao, "LLLLLL");
      return state
      // return { ...state, conexaoFluxo: conexao }
    case 'REMOVE_CONEXAO_FLUXO': {
      const conexaoFluxo = [...state.conexaoFluxo]
      conexaoFluxo.splice(action.id, 1)
      return { ...state, conexaoFluxo: conexaoFluxo }
    }
    case 'SET_BOTAO_DELETE': {
      return { ...state, botaoDelete: action.payload }
    }
    case 'DUPLICA_NODE': {
      return { ...state, duplicaNode: action.payload }
    } 
    case 'GET_ETIQUETAS':
      const etiquetas = action.payload.data.etiquetas
      return { ...state, etiquetas: etiquetas }       

    case 'SET_CALENDLY': {
      return { ...state, calendly: action.payload }
    } 

    case 'EDIT_CALENDLY': {
      return { ...state, calendly: action.payload }
    }    

    case 'GET_CALENDLY':
      const textAreasCalendly = action.payload.data.calendly
      return { ...state, calendly: [...state.calendly, textAreasCalendly] }

    case 'REMOVE_CALENDLY': {
      const removeCalendly = [...state.calendly]
      removeCalendly.splice(action.id, 1)
      return { ...state, calendly: removeCalendly }
    }

    default:
      return state
  }
}