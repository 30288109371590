// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
// import promise from 'redux-promise'
// import multi from 'redux-multi'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__
//   && window.__REDUX_DEVTOOLS_EXTENSION__()

// ** Create store
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
// const store = applyMiddleware(multi, thunk, promise)(createStore)(rootReducer, composeEnhancers)

export { store }
