const INITIAL_STATE = {
  lista: {
    mensagens: []
  },
  mensagemSelecionada: null,
  selectCategorias: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MENSAGENS_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'MENSAGEM_EDIT':
      return { ...state, mensagemSelecionada: action.payload.data.mensagem }
    case 'MENSAGEM_CLEAR':
      return { ...state, mensagemSelecionada: action.payload }
    case 'MENSAGEM_UPDATE':
      return { ...state }
    case 'MENSAGEM_CREATE':
      return { ...state }
    case 'GET_CATEGORIAS':
      return { ...state, selectCategorias: action.payload }
    default:
      return state
  }
}