const INITIAL_STATE = {
  lista: {
    carteiras: []
  },
  carteiraSelecionada: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CARTEIRAS_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'CARTEIRA_EDIT':
      return { ...state, carteiraSelecionada: action.payload.data.carteira }
    case 'CARTEIRA_CLEAR':
      return { ...state, carteiraSelecionada: action.payload }
    case 'CARTEIRA_UPDATE':
      return { ...state }
    case 'CARTEIRA_CREATE':
      return { ...state }
    default:
      return state
  }
}