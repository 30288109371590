const INITIAL_STATE = {
  empresaSelecionada: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EMPRESA_EDIT':
      return { ...state, empresaSelecionada: action.payload.data.empresa }
    default:
      return state
  }
}