const INITIAL_STATE = {
  lista: {
    etiquetas: []
  },
  etiquetaSelecionada: null,
  detalhesEtiqueta: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ETIQUETAS_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'ETIQUETA_EDIT':
      return { ...state, etiquetaSelecionada: action.payload.data }
    case 'ETIQUETA_CLEAR':
      return { ...state, etiquetaSelecionada: action.payload }
    case 'ETIQUETA_UPDATE':
      return { ...state }
    case 'ETIQUETA_CREATE':
      return { ...state }
    case 'ETIQUETA_SHOW':
      return { ...state, detalhesEtiqueta: action.payload.data }
    default:
      return state
  }
}