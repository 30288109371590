const INITIAL_STATE = {
  empresaSelecionada: null,
  planos: {
    mensal: [],
    trimestral: [],
    anual: []
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'EMPRESA_EDIT':
      return { ...state, empresaSelecionada: action.payload.data.empresa }
    case 'PLANOS_FETCH':
      console.log(action.payload.data.planos, 'REDUXXX')
      const planosCopy = {...state.planos}
      const mensal = action.payload.data.planos.filter(item => item.pla_frequencia == 'MENSAL')
      const trimestral = action.payload.data.planos.filter(item => item.pla_frequencia == 'TRIMESTRAL')
      const anual = action.payload.data.planos.filter(item => item.pla_frequencia == 'ANUAL')

      planosCopy.mensal = mensal
      planosCopy.trimestral = trimestral
      planosCopy.anual = anual
      return { ...state, planos: planosCopy }
    default:
      return state
  }
}