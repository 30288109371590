const INITIAL_STATE = {
  lista: {
    clientes: []
  },
  clienteSelecionado: null,
  detalhesCliente: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLIENTES_FETCHED':
      return { ...state, lista: action.payload.data }
    case 'CLIENTE_EDIT':
      return { ...state, clienteSelecionado: action.payload.data.cliente }
    case 'CLIENTE_CLEAR':
      return { ...state, clienteSelecionado: action.payload }
    case 'CLIENTE_UPDATE':
      return { ...state }
    case 'CLIENTE_CREATE':
      return { ...state }
    case 'CLIENTE_SHOW':
      return { ...state, detalhesCliente: action.payload.data.cliente }
    default:
      return state
  }
}